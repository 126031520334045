import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Button } from "../CompoundComponents/Button"
import { Columns, Column } from "../CompoundComponents/Columns"
import {
  Card,
  CardContent,
  CardImage,
  CardContentTitle,
} from "../CompoundComponents/Card"
import { Section } from "../CompoundComponents/Section"
import { ShoppingConsumer } from "../../layouts/index"

const ProductSection = ({ data, product }) => {
  return (
    <Section white>
      <Columns centered>
        <ShoppingConsumer>
          {({ cart, handleAddToCart }) => (
            <>
              {data.edges.map((item, i) => (
                <Column is3321>
                  <Card>
                    <CardImage>
                      <GatsbyImage
                        image={getImage(item.node.image.asset.gatsbyImageData)}
                      />
                    </CardImage>
                    <CardContent>
                      <CardContentTitle>
                        {item.node.name}
                      </CardContentTitle>
                    </CardContent>
                    <footer className="card-footer">
                      {cart.filter(
                        e =>
                          e === item.node.name
                      ).length > 0 ? (
                        <div className="card-footer-item">
                          <Link to="/contact">
                            <Button>
                              <span>
                                Оформить
                              </span>
                            </Button>
                          </Link>
                        </div>
                      ) : (
                        <div className="card-footer-item">
                          <button
                            onClick={() => handleAddToCart(item.node.name)}
                            className="button hvr-bounce-to-right is-size-6-mobile is-success is-outlined has-text-weight-bold is-normal has-family-pt-sans"
                          >
                            {product.edges[0].node.section_button}
                          </button>
                        </div>
                      )}

                      <div class="card-footer-item">
                        <Link to={item.node.pathname}>
                          <Button>
                            {product.edges[0].node.section_button_2}
                          </Button>
                        </Link>
                      </div>
                    </footer>
                  </Card>
                </Column>
              ))}
            </>
          )}
        </ShoppingConsumer>
      </Columns>
    </Section>
  )
}

export default ProductSection
