import React from "react"
import Sticky from "react-sticky-el"
import { graphql } from "gatsby"
import ProductSection from "../components/Sections/ProductSection"
import SEO from "../components/SEO/index"
import Contact from "../components/Sections/Contact"
import NavBar from "../components/navbar"
import localize from "../components/localize";

const ProductPage = ({ data }) => {
    const contact = data.contacts.edges[0].node;
    const address = data.address.edges;
  return (
    <>
      <SEO title="Dryprod" description="Наша продукция" />
      <Sticky className="sticky-selector" topOffset={150}>
        <div className="is-shadow">
            <NavBar data={data.menu} />
        </div>
      </Sticky>
      <ProductSection data={data.products} product={data.product} />
        <Contact contact={contact} address={address} />
    </>
  )
}

export default localize(ProductPage);

export const ProductPageQuery = graphql`
  query {
  product: allSanityProductpage {
        edges {
          node {
            section_button {
              _type
              en
              ru
            }
            section_button_2 {
              _type
              en
              ru
            }
            hero {
                en
                ru
                _type
              }
          }
        }
      }
  products: allSanityProducts {
        edges {
          node {
            name {
              _type
              en
              ru
            }
            pathname
            image {
              asset {
                gatsbyImageData(width: 200)
              }
            }
          }
        }
      }
    address: allSanityAddress {
        edges {
          node {
            address {
              en
              ru
              _type
            }
          }
        }
     }
    contacts: allSanityContact {
        edges {
          node {
            copyright {
              en
              ru
              _type
            }
            phone {
              en
              ru
            }
            phone_numbers {
              _type
              en
              ru
            }
            social {
              _type
              en
              ru
            }
            textarea {
              _type
              en
              ru
            }
            our_contacts {
              _type
              en
              ru
            }
            name {
              _type
              en
              ru
            }
            address {
              _type
              en
              ru
            }
            button {
              _type
              en
              ru
            }
            copyright {
              _type
              en
              ru
            }
            email {
              _type
              en
              ru
            }
            email_title {
              _type
              en
              ru
            }
            leave_request {
              _type
              en
              ru
            }
          }
        }
      }
    menu: allSanityMenu {
      edges {
        node {
           name {
             _type
             en
             ru
           }
         }
       }
    }
    card: allProductsYaml {
      edges {
        node {
          id
          title
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 150)
            }
          }
        }
      }
    }
    productwi: allProductwiYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 150)
            }
          }
        }
      }
    }
  }
`
